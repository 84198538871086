<script>
export default defineComponent({
  setup() {
    const header = 'header'
    const aside = 'aside'
    const loaderStore = useLoader()
    const isLoading = ref(false)

    watchEffect(() => {
      isLoading.value = loaderStore.isLoading
    })

    return {
      header,
      aside,
      isLoading,
    }
  },
})
</script>

<template>
  <div v-if="isLoading" class="loader" />
  <div v-else />
  <div class="fixed flex w-full">
    <NuxtLayout :name="aside" />
    <div class="flex-1 flex flex-col h-screen overflow-x-hidden">
      <NuxtLayout :name="header" />
      <div class="bg-[#F7F7F7] h-screen" :class="{ 'loader-container': isLoading }" style="overflow: auto !important;">
        <BaseBreadcrumb />
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.loader-container {
  opacity: 0.2;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
